import React from 'react';
import './Upcoming.css';
import {Events} from './data/Events';

function Upcoming() {
  return (
    <div className='events'>
      <div className='header-event'>
      <h2>
        <span>Our Services</span>
      </h2>      
      </div>
      <div className='scaj-services'>
      {Events.map ((item, index) => {
            return (
        <div className="service-item" key={index}>
            <ul>
              <li><i class={item.img_url}></i></li>
            </ul>
            <article>
              <h2 className="service-heading"> {item.title} </h2>
                <p>{item.content}</p>
            </article>
            </div>
            )
          })}         
      </div>      
    </div>
  )
}

export default Upcoming;