import React, { useRef, useState } from 'react';
import './Publish.css';
import { RiArrowDropDownLine } from 'react-icons/ri';
import {FaqData} from './data/FaqData';
import Infor from './Infor';


const AccordionItem = ({ question, answer, isOpen, onClick }) => {
    const contentHeight = useRef()
     return(
       <div className="wrapper" >
       <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
        <p className='question-content'>{question}</p>
        <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} /> 
       </button>
   
        <div ref={contentHeight} className="answer-container" style={
             isOpen
             ? { height: contentHeight.current.scrollHeight }
             : { height: "0px" }
            }>
         <p className="answer-content">{answer}</p>
        </div>
      </div>
     )
   }
   
function Publish() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  return (
    <>
    <div className='publish'>
        <div className='pub-left'>
            <div className='guidelines'>
                <h2>Author Guidelines for Submission to the SCAJ</h2>
                <div className='guide'>
                    <p>The Sports Casino Africa Journal (SCAJ) welcomes submissions of original research articles, reviews, case studies, and theoretical papers that explore the economic, social, legal, and regulatory aspects of sports betting and casinos in Africa. We are committed to publishing high-quality, peer-reviewed scholarship that advances understanding in these areas. Below are detailed guidelines to assist authors in preparing and submitting their manuscripts.</p>
                     <h4>1. Manuscript Types:</h4>
                    <ul className='guide_list'>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Original Research Articles: </strong>Empirical studies presenting new research findings.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Review Articles: </strong>Comprehensive overviews of specific topics within the journal's scope.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Case Studies: </strong>In-depth examinations of specific instances or events relevant to sports betting and casino operations.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Theoretical Papers: </strong>Analytical and conceptual contributions that provide new theoretical insights.</li>
                    </ul>
                    <h4>2. Manuscript Preparation:</h4>
                    <ul className='guide_list'>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Language: </strong>Manuscripts must be written in English, with clear and concise language. Non-native English speakers are encouraged to have their manuscripts professionally edited.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Title Page: </strong>The title page should include the manuscript title, author names and affiliations, corresponding author contact information, and any acknowledgments or funding information.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Abstract: </strong>Provide an abstract of no more than 250 words summarizing the study’s purpose, methodology, key findings, and implications.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Keywords: </strong>Include 4-6 keywords for indexing purposes.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Body of the Manuscript: </strong>The main text should be organized into the following sections:
                            <ul className='list_two'>
                                <li><strong>Introduction: </strong>State the research problem, objectives, and relevance.</li>
                                <li><strong>Literature Review: </strong>Review relevant literature to contextualize the study.</li>
                                <li><strong>Methodology: </strong>Describe the research design, data collection, and analysis methods.</li>
                                <li><strong>Results: </strong>Present the findings clearly and succinctly, supported by tables and figures where appropriate.</li>
                                <li><strong>Discussion: </strong>Interpret the findings, discussing their implications, limitations, and potential for future research.</li>
                                <li><strong>Conclusion: </strong>Summarize the main points and contributions of the study.</li>
                            </ul>
                        </li>
                    </ul>
                    <h4>3. Formatting Guidelines:</h4>
                    <ul className='guide_list'>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Length: </strong>Manuscripts should generally not exceed 8,000 words, including references and appendices.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Font and Spacing: </strong>Use a standard font (e.g., Times New Roman, 12-point) and double-space the text.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>References: </strong>Adhere to the APA (7th edition) citation style. All sources cited in the text must be included in the reference list.</li>
                        <li> <i class="fa-solid fa-angles-right"/><strong>Tables and Figures: </strong>Include tables and figures in the main text, numbered consecutively with descriptive captions. Ensure all visual materials are of high quality and appropriately cited.</li>
                    </ul>
                </div>
            </div>
            <div className='cont-seperator'></div>
            <div className='downloads'>
                <h3>Download Formating Samples</h3>
                <ul>
                    <li><a href=''><i class="fa-solid fa-file-pdf"/> APA Sample &#187;</a></li>
                </ul>
            </div>
        </div>
        <div className="pub-middle">
            <Infor />
        </div>
        {/*
        <div className="pub-right">
            <div className='ethics'>
                <div className='ethics_title'>
                    <h3>Ethical Guidlines for Authors</h3>
                    <img src='../../../images/icon-time.gif' alt='scaj ethical guideline'/>
                    <div className='clearer'></div>
                </div>
                <div className="ethics_content">
                    <p>SCAJ engages in policy advocacy and outreach activities to raise awareness of key issues, promote evidence-based policymaking, and advocate for responsible gambling practices in Africa.</p>
                    <p>The following <strong>ethical guidelines</strong> apply:</p>
                    <ul className='ethical_list'>
                        <li>&#187; The ethical code of conduct item 1</li>
                        <li>&#187; The ethical code of conduct item 2</li>
                        <li>&#187; The ethical code of conduct item 3</li>
                        <li>&#187; The ethical code of conduct item 4</li>
                        <li>&#187; The ethical code of conduct item 5</li>
                    </ul>
                </div>
            </div>
            <div className="ethics">
                <div className='ethics_title'>
                    <h3>Potential Areas of Conflict</h3>
                    <img src='../../../images/icon-feed.gif' alt='potential areas of conflict' />
                    <div className='clearer'></div>
                </div>
                <div className="ethics_content">
                    <p>This includes workshops on <strong>research methods</strong>, seminars on regulatory compliance, or online courses on responsible gambling practices.</p>
                    <p>This involves publishing <strong>policy briefs</strong>, participating in public debates or forums, and collaborating with relevant stakeholders to address societal concerns related to sports betting</p>
                </div>
            </div>
        </div> 
        */}
    </div>
    <div className="FAQ">
        <div className='faq_search'>
            <h4><span>FAQ |</span> Frequently Asked Questions</h4>
            <p>Search specific questions</p>
            <form className='faq-form'>
            <input type="text" name="search" placeholder='search..' pattern=".*\S.*" required/>
            <button type="submit" class="btn_search fa fa-search"></button>
            </form>
        </div>
        <div className='questions'>
            {FaqData.map((item, index) => (
                <AccordionItem
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={activeIndex === index}
                onClick={() => handleItemClick(index)}
                />
            ))}
        </div>
    </div>
    </>
  )
}

export default Publish;