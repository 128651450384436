import React from 'react';
import { NavLink } from 'react-router-dom';

function Infor() {
  return (
    <div className='peer_review_info'>
        <div className='peer-review'>
                <img src='../../../images/editors.jpeg'/>
               
                <h3>4. Submission Process:</h3>
                <ul>
                  <li><strong>Submission Platform: </strong>Manuscripts should be submitted electronically via the SCAJ submission portal on our website.</li>
                  <li><strong>Cover Letter: </strong>Include a cover letter stating the manuscript’s contribution to the field, confirming the work is original and not under consideration elsewhere, and declaring any potential conflicts of interest.</li>
                  <li><strong>Ethical Considerations: </strong>Authors must adhere to ethical standards in research. Studies involving human subjects must have approval from an appropriate ethics committee, and informed consent must be obtained.</li>
                </ul>              
                </div>
            <div className='peer-review1'>
                <h3>5. Peer Review and Publication:</h3>
                <ul>
                  <li><strong>Peer Review: </strong>All submissions undergo a double-blind peer-review process. Reviewers will evaluate the manuscript’s originality, quality, and relevance to the journal's scope.</li>
                  <li><strong>Revisions: </strong>Authors may be asked to revise their manuscripts based on reviewer feedback. Revised manuscripts must be resubmitted within the specified timeframe.</li>
                  <li><strong>Publication and Open Access: </strong>Accepted papers are published online in quarterly issues. SCAJ offers open access options to maximize visibility and impact.</li>
                </ul>
            </div>
            <div className='peer-review1'>
              <h3>6. Contact Information: </h3>
              <p>For questions regarding submissions or the review process, contact the editorial office at <span>scaj478@gmail.com</span></p>
              <p>We encourage authors to follow these guidelines carefully to ensure a smooth submission and review process. Thank you for considering SCAJ for your scholarly work.</p>
            </div>
    </div>
  )
}

export default Infor;