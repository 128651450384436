import React, { useRef, useState } from 'react';
import './Faq.css';
import Inf from './Infor';
import { RiArrowDropDownLine } from 'react-icons/ri';
import {FaqData} from './data/FaqData';

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
    const contentHeight = useRef()
     return(
       <div className="wrapper" >
       <button className={`question-container ${isOpen ? 'active' : ''}`} onClick={onClick} >
        <p className='question-content'>{question}</p>
        <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} /> 
       </button>
   
        <div ref={contentHeight} className="answer-container" style={
             isOpen
             ? { height: contentHeight.current.scrollHeight }
             : { height: "0px" }
            }>
         <p className="answer-content">{answer}</p>
        </div>
      </div>
     )
   }

function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  return (
    <div className='frq_asked'>
        <div className='frq_left'>
        <div className='faq_search'>
            <h4><span>FAQ |</span> Frequently Asked Questions</h4>
            <p>Search our FAQ for more details</p>
            <form className='faq-form'>
            <input type="text" name="search" placeholder='search..' pattern=".*\S.*" required/>
            <button type="submit" class="btn_search fa fa-search"></button>
            </form>
        </div>
        <div className='questions'>
            {FaqData.map((item, index) => (
                <AccordionItem
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={activeIndex === index}
                onClick={() => handleItemClick(index)}
                />
            ))}
        </div>
        </div>
        <div className='frq_right'>
            <Inf />
        </div>
    </div>
  )
}

export default Faq;