import React from 'react';
import './Scheduledetails.css';

function Scheduledetails() {
  return (
    <div className='schedule_details'>
        <h1>Publication Schedule</h1>
        <div className='submissions'>
            <h2>Issue #</h2>
            <span className='right_far'>{/*Submission deadline 15th March*/}</span>
            <h3>Manuscript Preparation and Submission</h3>
            <p>To ensure a smooth and efficient publication process, we kindly request authors to adhere to the following guidelines:</p>
            <ul>
                <li><strong>Manuscript Length:</strong> Manuscripts should fall within the range of 2,000 to 8,000 words.</li>
                <li><strong>Publication Fees:</strong> We offer two publication options:
                    <ul>
                        <li><strong>Open Access:</strong> This option includes a rigorous double-blind peer-review process for a fee of $69. Additionally, online access to your published work incurs a fee of $149 (total cost: $218).</li>
                        <li><strong>Subscription-Based Publication:</strong> For a one-time fee of $1,000, your work will be published in our subscription-based journal.</li>
                    </ul>
                </li>
                <li><strong>Submission:</strong> All inquiries and manuscript submissions should be directed to:</li>
                    <ul>
                        <li>Email: <strong>scaj478@gmail.com</strong></li>
                        <li>WhatsApp: <strong>+254704640067</strong></li>
                    </ul>
            </ul>
            <p className='note_bt'><br/><strong>Please note: </strong>We recommend utilizing the email address for formal inquiries and manuscript submissions. WhatsApp may be used for initial contact or quick questions.</p>
        </div>
    </div>
  )
}

export default Scheduledetails;