import React from 'react';
import './Search.css';

function Search() {
  return (
    <div className='perform-search'>
        <form>
            <input type="text" className="input-search" placeholder="Type to Search..." />
            <button class="btn-search2"><i class="fas fa-search"></i></button>
        </form>
    </div>
  )
}

export default Search;