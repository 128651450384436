import React from 'react';
import './Abstracts.css';

function Abstracts() {
  return (
    <div className='abstract_call'>
        <h1>Call for Abstracts OPEN for AFRICA</h1>
        <p>The deadline for all submissions is <span>September 10, 2024, at 11:59 pm EST</span>. All abstracts
        should address one of the following themes:</p>

        <ul>
            <li>The economic impact of sports betting on African countries, including job creation, tax
            revenue generation, and potential negative effects like problem gambling.</li>
            <li>The social and cultural implications of sports betting, exploring its influence on fan
            engagement, responsible gambling practices, and potential societal issues.</li>
            <li>Legal and regulatory frameworks governing sports betting in different African
            jurisdictions, analyzing best practices, challenges, and ongoing developments.</li>
            <li>The evolving technological landscape of sports betting in Africa, examining the role of
            mobile money, online platforms, and data analytics.</li>
            <li>The ethical considerations surrounding sports betting advertising, sponsorship deals, and
            potential conflicts of interest.</li>
        </ul>
        <p><br/><strong>*Cross-cutting themes*:</strong> We encourage the inclusion of cross-cutting themes into all
        presentations and speaking opportunities.</p>
        <p>After review all the abstract, qualified individuals will be invited to attend our conference on 10th
        October, 2024 at KICC Nairobi. You stand a chance to win <strong>$500,000 research grants</strong><br/></p>

        <h1>Submission procedure</h1>
        <p>Prepare <strong>250 words</strong> abstract on any the above themes following the below format:</p>
        
        <ul>
            <li><strong>Article title:</strong> Times New Roman; 14 pt; bold; alignment centered; single-line spacing; All Caps
            (max. 100 characters with spaces).</li>
            <li><strong>Author name and title: </strong>Times New Roman; bold; 14 pt; single-line spacing; alignment centered.</li>
            <li><strong>Affiliation and e-mail address: </strong>Times New Roman; italic; 12 pt; single-line spacing; alignment
            centered.</li>
            <li><strong>Abstract and keywords: 250 words</strong> (5-7 keywords); Times New Roman; 12 pt; single-line
            spacing; justify.</li>
        </ul>
        <p>Submit via email: <span>scaj478@gmail.com</span></p>
        <p>For inquiries, reach out via WhatsApp <span>+254704640067</span></p>
    </div>
  )
}

export default Abstracts;