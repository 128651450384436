import React from 'react';
import './Payment.css';

function Payment() {
  return (
    <div className='payment_option'>
        <div className='pay_header'>
          <h1>Available Payment Options</h1>
        </div>
        <div className='options'>
          <div className='payments'>
            <h3>PayPal</h3>
            <div className='pay_content'>
              <img src='../../../images/paypal.png' alt='paypal' />
              <p>
              What payment methods can I add and use with PayPal? With PayPal, you can add one or more of the following payment methods such as your bank account, Visa, Mastercard, Discover, or American Express card.
              <span>Learn More<span className='fa fa-chevron-right'></span></span> 
              </p>
            </div>
          </div>
          <div className='payments'>
            <h3>Master Card</h3>
            <div className='pay_content'>
              <img src='../../../images/mastercard.png' alt='mastercard' />
              <p>
              These include credit cards, debit cards and credit cards. In Central Europe, the Maestro card is also known, also called EC card, which is also licensed by MasterCard and issued by almost all banks. This is not a credit card, but a bank card that only debits the current account.
              <span>Learn More<span className='fa fa-chevron-right'></span></span> 
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Payment;