import React, { useState } from 'react';
import './About.css';
import Schedule from './Schedule';
import { reviews } from '../pages/data/ReviewProcess';
import ProcessAccordion from '../pages/pre_processes/ProcessAccordion';



function About() {
  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
     return setClicked("0");
    }
    setClicked(index);
   };


  return (
    <div className="about_section">
      <div className="abt_container">
        <div className='container-left'>
          <div className='history'>
            <h3> History of SCAJ </h3>
            <div className='line-bttm'>
              <span></span>
              <span></span>
            </div>
            <p className='p_bottom'>The Sports Casino Africa Journal (SCAJ) is a peer-reviewed academic journal dedicated to publishing high-quality research on the economic, social, legal, and regulatory aspects of sports betting in Africa.</p>
            <div className='ceo_stand'>
              <p><quote>"We provide peer review services to other journals or organizations interested in ensuring the quality and rigor of research related to sports betting in Africa"</quote></p>
              <h6><img src="../../../images/sports casino african journal  icon.jpg"/><span>Co-founder, Sylvester Omondi Okeyo</span></h6>
            </div>
          </div>
          
          <div className="aim-scope">
            <div className="scope_header">
                <div className='icon'>
                  <span><i class="fa fa-trophy"></i></span>
                </div>
                <div className='scope_title'>
                  <h5>Aims and Scope</h5>
                  <h6><span>Type of Research:</span>Journal for Sports</h6>
                </div>
            </div>
            <div className='scaj_goals'>
              <p>SCAJ aspires to become a trusted resource for academics, policymakers, industry stakeholders, and the public. Through high-quality research, informative content, and impactful outreach initiatives, SCAJ aims to contribute to a more sustainable and well-regulated sports betting landscape across the African continent</p>
              <ul>
                <li><i class="fa-solid fa-angles-right"/> Academics and researchers in sports economics, sociology, law, and public policy.</li>
                <li><i class="fa-solid fa-angles-right"/> Policymakers and regulators in African governments overseeing sports betting industries.</li>
                <li><i class="fa-solid fa-angles-right"/> Informed sports fans with an interest in the evolving sports betting landscape across Africa</li>
              </ul>
            </div>
          </div>

          <div className='editorial_board'>
            <div className="editor_header">
              <h5>The Editorial Board</h5>
              {/*<h6>The Team: <span>Samwel Okeyo, Sylvester Omondi</span></h6>*/}
            </div>
            <div className='editorial-team'>
              <div className='col-left'>
                <img src='../../../images/editor-team.png' alt='editors'/>
              </div>
              <div className='col-right'>
                <p>
                Our distinguished Editorial Board comprises 54 esteemed academics hailing from leading African universities and colleges. With expertise spanning law, economics, sociology, gender studies, psychology, sports science, and technology, this diverse panel ensures a comprehensive and rigorous review process. To uphold the highest standards of academic integrity and objectivity, we employ a double-blind peer review system, which precludes the disclosure of reviewer identities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='container-right'>
          <div className='review_process'>
            <h4 className='review_header'>Peer-review Process</h4>
            <p>
            The peer-review process for the <strong>SCAJ</strong>, which specializes in research at the intersection of sports, gaming, and gambling industries within the African context, follow a similar structure to general academic journals. Here’s an overview of the tailored peer-review process for SCAJ:
            </p>
            <ol className='process_accordion'>
              {reviews.map((items, index) => (
                <ProcessAccordion onToggle={() => handleToggle(index)}
                active={clicked === index}
                key={index} items = {items}
                />
              ))}
            </ol>
          </div>
          <div className="publication_schedule">
              <Schedule />
          </div>
        </div>
      </div>
      <div className='bg_set'>
      <div className='the_founders'>
        <h1>The Co-founders</h1>
        <div className='co-founder'>
          <div className='founder'>
            <h3>Sylvester Omondi</h3>
            <div className='founder_details'>
              <img src='../../../images/scaj-slivester.png' alt='sports casino africa journal co-founder Silvester' />
              <p>A certified Geography and 
                  Business Studies teacher, 
                  Sylvester brings valuable 
                  experience in research 
                  methodologies, data analysis,
                   and critical thinking skills
                   to SCAJ. His educational background allows him to analyze the economic and social impacts of sports betting on African communities.
              </p>
            </div>
          </div>
          <div className='founder'>
            <h3>Samwel Otieno</h3>
            <div className='founder_details'>
              <img src='../../../images/scaj-cofounder-removebg-preview.png' alt='sports casino africa journal co-founder'/>
              <p>
              Samwel, a social scientist with a background 
              in Gender, Sociology, Development, and 
              Psychology, possesses understanding of the
              social implications of sports betting, 
              particularly its potential impact on gender
                dynamics, responsible gambling practices,
                and potential societal issues. 
                His expertise is crucial in shaping 
                SCAJ's content and outreach activities 
                surrounding the economic, social, legal, 
                and regulatory aspects of sports betting 
                and casino in Africa.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/*<div className="editor_staff">
        <div className='editors_header'>
          <h3>Editorial Staff</h3>
        </div>
        <Team />
      </div>*/}
    </div>
  )
}

export default About;