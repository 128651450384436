export const  data = [
    {
        title: 'Olympics in France',
        industry: 'Sports',
        date: '31-July-2024',
        brief: 'Men\'s Footbal Games at the Olympics',
        imgurl: '../../../images/okeyo3.jpeg',
        storyurl: 'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.campusfrance.org/en/actu/club-france-promouvoir-la-recherche-et-l-innovation-aux-jo-de-paris-2024&ved=2ahUKEwixmtK12t2HAxWORvEDHUl9Ok4QFnoECDYQAQ&usg=AOvVaw2K6ILPXFOnO8qThyqak58D'
    },
    {
        title: 'New betting regulations across Africa',
        industry: 'Betting',
        date: '30-July-2024',
        brief: 'The betting and gaming landscape in Africa is undergoing significant shifts.',
        imgurl: '../../../images/okeyo5.jpeg',
        storyurl: 'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.theeastafrican.co.ke/tea/sponsored/the-impact-of-kenya-s-gambling-control-bill-4562704&ved=2ahUKEwjb8_3w2t2HAxUNcfEDHUb7JvEQFnoECBkQAQ&usg=AOvVaw0WQGYCIVt9HEmzv5HrRoHq'
    },
    {
        title: 'Impacts of 2024/2025 finance bills on betting activities across Africa',
        industry: 'Betting',
        date: '29-July-2024',
        brief: 'The main goal of this bill is to manage different types of gambling like betting',
        imgurl: '../../../images/okeyo1.jpeg',
        storyurl: 'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://igamingbusiness.com/legal-compliance/regulation/kenya-introduces-new-gambling-regulation-bill/&ved=2ahUKEwjb8_3w2t2HAxUNcfEDHUb7JvEQFnoECB8QAQ&usg=AOvVaw2z3uxD20qMVllGQewtaE6d'
    } 
];

export const events = [
    {
        title: '2024-25 European League Starts',
        imgs: '../.../../images/okeyo2.jpeg',
        workshop: "Sports", 
        date: '05-August-2024',
        details: 'The return of club football is just around the corner.',
        url: 'https://www.bbc.com/sport/football/articles/cp68p120pj3o'
    },
    {
        title: 'Gambling Regulations Update',
        imgs: '../.../../images/okeyo4.jpeg',
        workshop: "Betting", 
        date: '30-July-2024',
        details: 'The betting and gaming landscape in Africa is undergoing significant shifts.',
        url: '#'
    }
];