import { useRef } from "react";

const ProcessAccordion = ({ items, onToggle, active }) => {
    const { process1, details } = items;

    const contentEl = useRef();
    return (
      <li className={`accordions_item ${active ? "active" : ""}`}>
        <button className="button" onClick={onToggle}>
          <span>{process1}</span>
          <span className="control">{active ? "—" : "+"} </span>
        </button>
        <div ref={contentEl} className='process_wrapper'
        style={
            active
              ? { height: contentEl.current.scrollHeight }
              : { height: "0px" }
          }>
          <div className="the_details">{details}</div>
        </div>
      </li>
    );
  };
  

export default ProcessAccordion;