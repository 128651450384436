import React, { Component} from 'react';
import Sidebar from '../Sidebar';
import Upcoming from './Upcoming';
import { NavLink } from 'react-router-dom';

import './Home.css';

let About = "../../../images/post.jpg";
let process = "../../../images/process_review.png";

function Home() {
  return (
    <div className="main-content">
        <div className='top-main'>
            <div className='right-side'>
              <div className='scaj_intro'>
                <h1>Sports Casino Africa Journal (SCAJ)</h1>
                <p>
                SCAJ Enterprise, trading as the Sports Casino Africa Journal (SCAJ), is a newly established online journal and publications brand dedicated to fostering a deeper understanding of the multifaceted impact of sports betting and casinos in Africa.
                </p>
                <p><strong>Vision</strong> The Sports Casino Africa Journal (SCAJ) is a peer-reviewed academic journal dedicated to publishing high-quality research on the economic, social, legal, and regulatory aspects of sports betting in Africa</p>
                <p><strong>Publication Schedule:</strong> SCAJ is published quarterly (four issues per year) in an online format with open access options to maximize readership and scholarly impact.</p>
                        <NavLink className="readmore" to='/about-us'>Learn More <i class="fa-solid fa-angles-right"></i></NavLink>

                  <div className='review_process'>
                    <img src={process} alt='Sports Casino Africa Journal peer review process'/>
                  </div>
              </div>
            </div>
            <div className='sidebar'>
                <Sidebar />
            </div>
        </div>
        {/* submit abstract to Sports Casino Africa Journal */}
        <div className='submit_paper'>
              <h2>Submit an Article Now</h2>
              <div className='submission'>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>Author's Name</label><br/>
                    <input type='text' placeholder='e.g Jackson Samuel'/>
                  </div>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>Email</label><br/>
                    <input type='text' placeholder='mail@domain.com'/>
                  </div>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>ORCID Number</label><br/>
                    <input type='text' placeholder='e.g 56478-CD'/>
                  </div>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>Article Title</label><br/>
                    <input type='text' placeholder='Title'/>
                  </div>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>Journal Name</label><br/>
                    <select>
                      <option>--</option>
                      <option>Journal Name1</option>
                      <option>Journal Name2</option>
                      <option>Journal Name3</option>
                    </select>
                  </div>
                  <div className='paper_inputs'>
                    <label htmlFor='name'>Upload Article(pdf,word)</label><br/>
                    <input type='file' placeholder='pdf, word format'/>
                  </div>
              </div>
              <div className='submit_paper_btn'>
                <div className='deadline'>
                  <p>{/*Sumbission deadline 10th September 2024*/}</p>
                </div>
                <div className='remit_paper'>
                  <button>Submit</button>
                </div>
              </div>
            </div>
            
        <div className='tragets'>
                {/* Sports Casino Africa Journal audience */}
                <div className="box">
                <h2>Target Audience</h2>
                <p>SCAJ aims to serve a broad audience including:</p>
                <ul>
                  <li>
                    <i class="fa-solid fa-angles-right"></i>
                    Academics and researchers in sports economics, sociology, law, and public policy.
                  </li>
                  <li>
                    <i class="fa-solid fa-angles-right"></i>
                    Policymakers and regulators in African governments overseeing sports betting industries.
                  </li>
                  <li>
                    <i class="fa-solid fa-angles-right"></i>
                    Industry professionals working in sports betting companies, casinos, and affiliated businesses
                  </li>
                  <li>
                    <i class="fa-solid fa-angles-right"></i>
                    Informed sports fans with an interest in the evolving sports betting landscape across Africa.
                  </li>
                </ul>
              </div>
              <div className="box">
                <h2>Content</h2>
                <p>SCAJ publishes original research articles, review papers, case studies, and commentaries focusing on, but not limited to, the following areas:</p>
                <ul>
                  <li>
                  <i class="fa-solid fa-angles-right"></i>
                  The economic impact of sports betting on African countries, including job creation, tax revenue generation, and potential negative effects like problem gambling
                  </li>
                  <li>
                  <i class="fa-solid fa-angles-right"></i>
                  The social and cultural implications of sports betting, exploring its influence on fan engagement, responsible gambling practices, and potential societal issues.
                  </li> 
                  <li>
                  <i class="fa-solid fa-angles-right"></i>
                  Legal and regulatory frameworks governing sports betting in different African jurisdictions, analyzing best practices, challenges, and ongoing developments.
                  </li> 
                  <li>
                  <i class="fa-solid fa-angles-right"></i>
                  The evolving technological landscape of sports betting in Africa, examining the role of mobile money, online platforms, and data analytics.
                  </li> 
                  <li>
                  <i class="fa-solid fa-angles-right"></i>
                  The ethical considerations surrounding sports betting advertising, sponsorship deals, and potential conflicts of interest.
                  </li>             
                </ul>
              </div>     
            </div>
        <div className='bottom-main'>
            <div className='featured-services'>
                <Upcoming />
            </div> 
        </div>
    </div>
  )
}

export default Home;