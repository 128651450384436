import React from 'react';
import './Tablecontent.css';

function TableContent() {
  return (
    <div className="table_content">
        <div className='table_header'>
            <h2>Table of Content</h2>
        </div>
        <div className='content_sections'>
        <ul className="tree">
            <li id="menu12">
                <label htmlFor="menu12">
                <a>Social Sciences</a>
                </label>
                <input defaultChecked="" id="menu12" defaultValue="" type="checkbox" />
                <ul>
                <li id="menu13">
                    <label htmlFor="menu13">
                    <a>Papers on social justice</a>
                    </label>
                    <input defaultChecked="" id="menu13" defaultValue="" type="checkbox" />
                    <ul>
                    <li id="menu14">
                        <a>
                        <label htmlFor="menu14">How marginalized communities are benefing from outreach programs</label>
                        <input
                            defaultChecked=""
                            id="menu14"
                            defaultValue=""
                            type="checkbox"
                        />
                        </a>
                    </li>
                    <li id="menu15">
                        <a>
                        <label htmlFor="menu14">The cost of seeking justice in Africa</label>
                        <input
                            defaultChecked=""
                            id="menu14"
                            defaultValue=""
                            type="checkbox"
                        />
                        </a>
                    </li>
                    </ul>
                </li>
                </ul>
            </li>
            <li id="menu15">
                <label htmlFor="menu15">
                <a>Sprots and Betting</a>
                </label>
                <input defaultChecked="" id="menu15" defaultValue="" type="checkbox" />
                <ul>
                <li id="menu17">
                    <label htmlFor="menu17">
                    <a>Football and Other Sports</a>
                    </label>
                    <input defaultChecked="" id="menu17" defaultValue="" type="checkbox" />
                    <ul>
                    <li id="menu18">
                        <a>
                        <label htmlFor="menu18">How betting firms are thriving in Africa</label>
                        <input
                            defaultChecked=""
                            id="menu18"
                            defaultValue=""
                            type="checkbox"
                        />
                        </a>
                    </li>
                    <li id="menu18">
                        <a>
                        <label htmlFor="menu18">How to manage betting addiction in youths</label>
                        <input
                            defaultChecked=""
                            id="menu18"
                            defaultValue=""
                            type="checkbox"
                        />
                        </a>
                    </li>
                    </ul>
                </li>
                </ul>
            </li>
            <li id="menu20">
                <a>
                <label htmlFor="menu20">Special Issues on Gambling</label>
                <input defaultChecked="" id="menu20" defaultValue="" type="checkbox" />
                </a>
            </li>
            </ul>
        </div>  
    </div>
  )
}

export default TableContent;