import React, {useEffect} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Footer.css';
import Navbar from './Navbar';

function Footer() {
    //Extracts pathname property(key) from an object
    const { pathname } = useLocation();
    
    //Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

  return (
    <footer className="footer-section">
        <div className="ft_container">
            <div className="footer-cta">
            <div className="ft_row">
                <div className="contact_info ">
                <div className="single-cta">
                    <i className="fas fa-map-marker-alt" />
                    <div className="cta-text">
                    <h4>Find Us</h4>
                    <span>Mfangano Street, Nairobi Kenya</span>
                    </div>
                </div>
                </div>              
                
                <div className="contact_info">
                <div className="single-cta">
                    <i className="fas fa-phone" />
                    <div className="cta-text">
                    <h4>Call Us</h4>
                    <span>+254704640067</span>
                    </div>
                </div>
                </div>
                <div className="contact_info">
                <div className="single-cta">
                    <i className="fas fa-phone" />
                    <div className="cta-text">
                    <h4>Mail Us</h4>
                    <span>scaj478@gmail.com</span>
                    </div>
                </div>
                </div>
            </div>
            </div>
            
            <div className="footer-content">
            <div className="ft_row">
                <div className="links_info">
                <div className="footer-widget">
                    <div className="footer-logo">
                    <NavLink to='/'>
                        <img
                        src="../../images/scaj_logo.png"
                        className="img-fluid"
                        alt="logo"
                        />
                    </NavLink>
                    </div>
                    <div className="footer-text">
                    <p>
                    The Sports Casino Africa Journal (SCAJ) is a peer-reviewed
                     academic journal dedicated to publishing high-quality
                      research on the economic, social, legal, and regulatory
                       aspects of sports betting in Africa.
                    </p>
                    </div>
                    <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com/sportscasinoafricajournal" target='_blank'>
                        <i className="fab fa-facebook-f facebook-bg" />
                    </a>
                    <a href="https://x.com/scaj478" target='_blank'>
                        <i className="fab fa-x-twitter twitter-bg" />
                    </a>
                    <a href="#">
                        <i className="fab fa-linkedin-in linkedin-bg" />
                    </a>
                    <a href="#">
                        <i className="fab fa-instagram instagram-bg" />
                    </a>
                    </div>
                </div>
                </div>
                <div className="links_info">
                <div className="footer-widget">
                    <div className="footer-widget-heading">
                    <h3>Quick Links</h3>
                    </div>
                    <ul>
                    <li>
                        <NavLink to='/'>Home</NavLink>
                    </li>
                    <li>
                    <NavLink to='/about-us'>About</NavLink>
                    </li>
                    <li>
                        <NavLink to='/publish-with-us'>Publish with Us</NavLink>
                    </li>
                    <li>
                        <NavLink to='/current-issue'>Current Issue</NavLink>
                    </li>
                    <li>
                    <NavLink to='/archives'>Archives</NavLink>
                    </li>
                    <li>
                        <NavLink to='/news-and-events'>News & Events</NavLink>
                    </li>
                    <li>
                        <NavLink to='contact-us'>Contact us</NavLink>
                    </li>
                    <li>
                        <NavLink to='/register-with-us'>Register</NavLink>
                    </li>
                    <li>
                        <NavLink to='/call-for-abstracts'>Call for Abstract</NavLink>
                    </li>
                    <li>
                        <NavLink to='/login'>Sign In</NavLink>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="links_info">
                <div className="footer-widget">
                    <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                    </div>
                    <div className="footer-text">
                    <p>
                        Don’t miss to subscribe to our new feeds, kindly fill the form
                        below.
                    </p>
                    </div>
                    <div className="subscribe-form">
                    <form action="#">
                        <input type="text" placeholder="Email Address" />
                        <button>
                        <i className="fab fa-telegram-plane" />
                        </button>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="ft_container">
            <div className="ft_row">
                <div className="copyright_info">
                <div className="copyright-text">
                    <p>
                    Copyright © 2024, All Right Reserved{" "}
                    <NavLink to='/'>SCAJ</NavLink>
                    </p>
                </div>
                </div>
                <div className="display_none">
                <div className="footer-menu">
                    <ul>
                    <li>
                        <NavLink to='/terms-and-conditions'>Terms & Conditions</NavLink>
                    </li> 
                    <li>
                        <NavLink to='/faq'>Help</NavLink>
                    </li>                
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer;