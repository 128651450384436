import React from 'react';
import './Login.css';

function Login() {
  return (
    <div className='login'>
        <div className='left-login'>
        <div className="sign-in">
        <img src='../../../images/user-lock.svg'/>
            <h2>Sign In</h2>
            <form action="" method="post" className="form">
                <div className="form__field">
                <input type="email" placeholder="info@mailaddress.com" />
                </div>
                <div className="form__field">
                <input type="password" placeholder="••••••••••••" />
                </div>
                <p><a href='#'>Forgot Password?</a></p>
                <div className="form__field">
                    <button value='Login' className='login-submit'>Log In
                    </button>
                </div>
            </form>    
            <p>
                Don't have an accout? <a href="#">Sign Up</a>
            </p>
            </div>
        </div>
        
    </div>
  )
}

export default Login;