import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="org_contact">
    <div className="contact_header">
        <h1>Contact <span>Us</span></h1>
        <p>SCAJ replies to messages within 24 hrs</p>
    </div>
    <div className="contact_info1">
        <div className='info_left1'>
            <h3>Have Any Question?</h3>
            <p>Call us for instant feedback</p>
            <div className='info-details'>
                <div className='info_icon'>
                    <i class="fas fa-mobile-alt"></i>
                </div>
                <div className='the_link-data'>
                <h4 class="d-inline-block">Phone :
                    <br/>
                <span>+254704640067</span>
                </h4>
                </div>
            </div>
            <div className='info-details'>
                <div className='info_icon'>
                    <i class="far fa-envelope"></i>
                </div>
                <div className='the_link-data'>
                <h4 class="d-inline-block">EMAIL :
                    <br/>
                <span>scaj478@gmail.com</span>
                </h4>
                </div>
            </div>
            <div className='info-details'>
                <div className='info_icon'>
                    <i class="fas fa-map-marker-alt"></i>
                </div>
                <div className='the_link-data'>
                <h4 class="d-inline-block">Address :
                    <br/>
                <span>Mfangano Street, Nairobi Kenya</span>
                </h4>
                </div>
            </div>
        </div>
        <div className='info_right1'>
            <form className='form_inputs1'>
                <div className='top_inputs'>
                    <input className='lft' type='text' placeholder='Name'/>
                    <input className='rgt' type='email' placeholder='Email'/>
                </div>
                <div className='middle_inputs'>
                    <input type='text' placeholder='Subject' />
                    <textarea type='text' placeholder='Message' />
                </div>
                <button className='contact_submit_btn btt_bg' type='submit'>Send <i class="fas fa-arrow-right"/></button>
            </form>
        </div>
    </div>
    </div>
  )
}

export default Contact;