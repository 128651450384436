import React from 'react';
import './Archives.css';
import ArticleSearch from './ArticleSearch';
import { Articles } from './data/Articles';

function Archives() {
  return (
    <div className='archived'>
        <ArticleSearch />
        <div className='archive_articles'>
            <h3 className='h_top'>Archives</h3>
            <p>These articles cover a wide range of topics related to sports betting and gambling in Africa, providing a diverse set of perspectives and insights.</p>
            <div className="meta_search">
                <div className="left">
                    <a href="#">« Previous page</a>
                </div>
                <div className="right">
                    <a href="#">Next page »</a>
                </div>    
            </div>
        </div>
        { Articles.map ((item, index) => {
            return (
                <div className='each_article'>
                    <div className="archive-post-date">
                        <div className="archive-post-day">{item.publication_month}</div>
                        <div className="archive-post-month">{item.publication_year}</div>
                    </div>
                    <div className='article_titles'>
                        <h3><a href={item.pdf} target='_blank'>{item.title}</a></h3>
                        <div className='data_section'>
                            <div className='post_date'>
                                <span> Published {item.publication_month}, {item.publication_year} in <a href=''>{item.category}</a></span>
                            </div>
                            <div className="volume">
                                <span>{item.issue} </span>
                                <span>Donwload: <a href={item.pdf} target='_blank'> <i class='fa fa-file-pdf' /> Pdf </a> <a href={item.epub} target='_blank'><i class='fa fa-file-lines' /> ePub</a></span> 
                            </div>
                        </div>
                    </div>
                </div>
            )
        }) }    
            <div className="meta_search">
                <div className="left">
                    <a href="#">« Previous page</a>
                </div>
                <div className="right">
                    <a href="#">Next page »</a>
                </div>    
            </div>   
        </div>
  )
}

export default Archives;