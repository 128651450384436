export const Events = [
    {
        img_url: "fa-solid fa-desktop",
        title: "Peer Review Services",
        content: "SCAJ provides peer review services to other journals or organizations interested in ensuring the quality and rigor of research related to sports betting in Africa. This service includes managing the peer review process, identifying suitable reviewers, and providing feedback to authors and editors"
    },
    {
        img_url: "fa-brands fa-telegram",
        title: "Consultation and Advisory Services",
        content: "SCAJ offers consultation and advisory services to policymakers, regulators, industry professionals, and other stakeholders seeking expert guidance on issues related to sports betting in Africa. This involves providing customized reports, conducting workshops or training sessions, or offering strategic advice based on the journal's expertise and research findings."
    },
    {
        img_url: "fa-solid fa-chart-line",
        title: "Data Analysis and Research Support",
        content: "Given its focus on the economic, social, legal, and regulatory aspects of sports betting, SCAJ provides data analysis and research support services to researchers, organizations, and governments interested in analyzing trends, conducting surveys, or evaluating policies related to sports betting in Africa. This includes access to datasets, statistical analysis tools, and expertise in research methodologies."
    },
    {
        img_url: "fa-solid fa-building-columns",
        title: "Conference and Event Organization",
        content: "SCAJ organizes conferences, seminars, or webinars focused on topics relevant to sports betting in Africa, bringing together researchers, policymakers, industry professionals, and other stakeholders to exchange knowledge, share best practices, and discuss emerging issues. These events also provide opportunities for networking and collaboration."
    },
    {
        img_url: "fa-sharp fa-solid fa-credit-card",
        title: "Policy Advocacy and Outreach",
        content: "SCAJ engages in policy advocacy and outreach activities to raise awareness of key issues, promote evidence-based policymaking, and advocate for responsible gambling practices in Africa. This involves publishing policy briefs, participating in public debates or forums, and collaborating with relevant stakeholders to address societal concerns related to sports betting."
    },
    {
        img_url: "fa-solid fa-bag-shopping",
        title: "Training and Capacity Building",
        content: "SCAJ offers training programs, capacity-building initiatives, or educational resources aimed at enhancing the skills and knowledge of researchers, policymakers, industry professionals, and other stakeholders involved in the sports betting sector in Africa. This includes workshops on research methods, seminars on regulatory compliance, or online courses on responsible gambling practices."
    },  
]