import React, {useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import Search from './Search';
let Logo = '../../images/scaj_logo.png';

function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])  

  const [seeSearch, setSearch] = useState(false);
  function togglePop() {
    setSearch(!seeSearch);
  };

  {/* Subcribe link clicked? scroll to subscribe box */}
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className='nav_menu_main'>
      <div className='header-top'>
        <ul className='left-menu'>
        <li><NavLink to='faq'
          className={({ isActive }) => {
            return isActive ? "active" : "";
            }}
          >FAQ</NavLink></li>
          <li><button className='rss' onClick={scrollToBottom}>Subscribe</button></li>
        </ul>
        <ul  className='right-menu'> 
          <li><NavLink to='login'
          className={({ isActive }) => {
            return isActive ? "active" : "";
            }}
          >Login</NavLink></li>
          <li><NavLink to='/register-with-us'>Sign Up</NavLink></li>
        </ul>
      </div>
      <div className='logo-middle'>
        <div className='logo'>
          <img src={Logo} alt='SCAJ Logo' />
        </div>
        <div className='call-action'>
        <NavLink to='/call-for-abstracts' className="button">Call for Abstract &#187;</NavLink>
        </div>
      </div>
      <div className='nav-menu'>
        <div className='menu-items'>
        <div className='menuOpen' onClick={toggleNav}>
            <div>☰</div>
          </div>

         {(toggleMenu || screenWidth > 768) && (
            <ul>
            <li className='active'>
              <NavLink to='/'
                className={({ isActive }) => {
                return isActive ? "active" : "";
                }} 
                onClick={toggleNav}
              >
              Home
              </NavLink></li>
            <li><NavLink to='/about-us' onClick={toggleNav}>About</NavLink></li>
            <li><NavLink to='/publish-with-us'onClick={toggleNav}>Publish with Us</NavLink></li>
            <li><NavLink to='/payment-options'onClick={toggleNav}>Payment Options</NavLink></li>
            <li><NavLink to='/current-issue'onClick={toggleNav}>Current Issue</NavLink></li>
            <li><NavLink to='/archives'onClick={toggleNav}>Archives</NavLink></li>
            <li><NavLink to='/news-and-events' onClick={toggleNav}>News & Events</NavLink></li>
            <li><NavLink to='/contact-us' onClick={toggleNav}>Contact Us</NavLink></li>
          </ul>
          )}
        </div>
        <div class="wrap-search">
        <button className="btn-search" onClick={togglePop}><i class="fas fa-search"></i></button>
        {seeSearch ? <Search toggle={togglePop} />: null}
        </div>
      </div>
    </div>
  )
}

export default Navbar;