export const reviews = [
    {
        process1: 'Submission of Manuscript',
        details: 'Authors submit manuscripts through SCAJ’s online submission system. Submissions should explore topics such as sports betting, gaming regulations, market analyses, or the sociocultural impact of gambling in Africa. Authors must adhere to the journal\'s specific formatting and ethical guidelines, particularly concerning sensitive issues like gambling addiction'
    },
    {
        process1: 'Initial Editorial Assessment',
        details: 'The editorial team, led by the Editor-in-Chief, conducts an initial assessment to ensure the submission aligns with the journal’s focus on sports and casino gaming in Africa. This stage involves a quick check for relevance, basic quality of the manuscript, and adherence to ethical standards, including proper handling of data and participant consent.'
    },
    {
        process1: 'Assignment to an Editor',
        details: 'Manuscripts that pass the initial screening are assigned to an associate editor with expertise in the relevant area, such as sports analytics, gambling law, or economic impacts of gaming. The associate editor reviews the paper\'s novelty, relevance, and scientific rigor. At this stage, the paper may be desk rejected if it is found lacking in these areas or if it falls outside the journal’s scope.'
    },
    {
        process1: 'Selection of Peer Reviewers',
        details: 'The associate editor selects two or more peer reviewers with specialized knowledge in the manuscript\'s subject matter. For SCAJ, reviewers are chosen for their expertise in areas such as sports management, casino operations, regulatory issues, or gambling behavior. Reviewers are also screened for potential conflicts of interest, especially important in the sensitive area of gambling research.'
    },
    {
        process1: 'Peer Review Process',
        details: 'The reviewers assess the manuscript’s methodology, accuracy of data analysis, relevance to African contexts, and contribution to the field. Special attention is given to the ethical considerations of gambling research, ensuring that studies on sensitive topics are conducted responsibly. Reviewers provide a comprehensive report, often with recommendations for improving the study’s clarity, relevance, and impact.'
    },
    {
        process1: 'Editorial Decision',
        details: 'Based on the reviewers’ reports, the associate editor makes an initial decision. The decision could be to accept the manuscript, request major or minor revisions, or reject it. The editorial decision considers the reviewers\' comments, the manuscript\'s fit with SCAJ\'s thematic focus, and its potential contribution to the field.'
    },
    {
        process1: 'Revision and Resubmission',
        details: 'Authors revise their manuscripts according to the reviewers’ feedback and submit a revised version along with a response letter detailing how each comment was addressed. In the case of studies involving human subjects, additional scrutiny is applied to ensure ethical guidelines are met, especially concerning participant consent and data privacy.'
    },
    {
        process1: 'Final Decision',
        details: 'Upon resubmission, the revised manuscript may be sent back to the original reviewers for further evaluation or reviewed by the editorial team. The final decision is made based on whether the revisions have sufficiently addressed the reviewers\' concerns and whether the manuscript meets the journal\'s standards.'
    },
    {
        process1: 'Copyediting and Proofreading',
        details: 'Accepted manuscripts undergo a final editorial review for language clarity, proper citation, and adherence to SCAJ’s formatting guidelines. The journal’s editorial staff ensures that the language is clear and accessible, given the journal\'s diverse readership, which may include policymakers, academics, and industry professionals.'
    },
    {
        process1: 'Publication',
        details: 'The final version of the manuscript is published online, with options for supplementary materials such as data sets, additional analyses, or multimedia content. SCAJ ensures that published papers are accessible to a broad audience, including practitioners in the sports and gaming industries, researchers, and the general public interested in the field.'
    },
];