import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import NavBar from './components/Navbar';
import Home from './components/pages/Home';
import Footer from './components/Footer';

import About from './components/pages/About';
import Publish from './components/pages/Publish';
import Issue from './components/pages/Currentissue';
import Archives from './components/pages/Archives';
import NewsEvents from './components/pages/NewsEvents';
import Contact from './components/pages/Contact';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Faq from './components/pages/Faq';
import Paymentoption from './components/pages/Payment';
import Terms from './components/pages/Terms';
import Abstracts from './components/pages/Abstracts';
import SheduleDetails from './components/pages/Scheduledetails';
import Scheduledetails from './components/pages/Scheduledetails';

function App() {
  return (
    <div className="App">
      <Router basename="/">
      <NavBar />
        <Routes>
          <Route path='/' Component={Home}/>
          <Route path='/about-us' Component={About}/>
          <Route path='/publish-with-us' Component={Publish}/>
          <Route path='/current-issue' Component={Issue}/>
          <Route path='/archives' Component={Archives}/>
          <Route path='/news-and-events' Component={NewsEvents}/>
          <Route path='/contact-us' Component={Contact}/>
          <Route path='/login' Component={Login}/>
          <Route path='/register-with-us' Component={Register}/>
          <Route path='/faq' Component={Faq}/>
          <Route path='/payment-options' Component={Paymentoption}/>
          <Route path='/terms-and-conditions' Component={Terms}/>
          <Route path='/call-for-abstracts' Component={Abstracts}/>
          <Route path='/details-of-publication-schedule' Component={Scheduledetails}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
