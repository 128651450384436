import React from 'react';
import './ArticleSearch.css';

function ArticleSearch() {
  return (
    <div className='search_articles'>
        <div className='art_search_form'>
            <input type='text' className="search_input" placeholder='Search' />
            <span className='input_btn'>
                <button className='search_button' type='button'>
                <span class="fa fa-search"></span>
                </button>
            </span>
        </div>
        <div className='search_criteria'>
            <div className='start_search'>
                <span>From:</span>
                <input type='date' placeholder='From'/>
                <span>To:</span>
                <input type='date' placeholder='From' defaultValue="From"/>
                <button>Find Results &#187;</button>
            </div>
        </div>
    </div>
  )
}

export default ArticleSearch;