import React from 'react';
import './Register.css';

function Register() {
  return (
    <div className="Register">
        <div className="container">
            <form action='' method="post">
            <fieldset>
                <legend>
                <center>
                    <h2>
                    <b>Registration Form</b>
                    </h2>
                </center>
                </legend>
                <br />
                <div className="signup_content">
                    <div className='signup-img'>
                        <img src='../../../images/signup-img.jpg' alt='' />  
                    </div>
                    <div className="signup_form">
                        <form className='register_form'>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="name">Name :</label>
                                <input type="text" name="name" id="name" required="" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="l_name">Last Name :</label>
                                <input type="text" name="l_name" id="l_name" required="" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="address">Address :</label>
                                <input type="text" name="address" id="address" required="" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email ID :</label>
                                <input type="email" name="email" id="email" required="" />
                            </div>
                        </div> 
                        <div className="form-radio">
                            <label htmlFor="gender" className="radio-label">
                                Gender :
                            </label>
                            <div className="form-radio-item">
                                <input type="radio" name="gender" id="male" defaultChecked="" />
                                <label htmlFor="male">Male</label>
                                <span className="check" />
                            </div>
                            <div className="form-radio-item">
                                <input type="radio" name="gender" id="female" />
                                <label htmlFor="female">Female</label>
                                <span className="check" />
                            </div>
                            <div className="form-radio-item">
                                <input type="radio" name="gender" id="nonspecific" />
                                <label htmlFor="nonespecific">Choose not to specify</label>
                                <span className="check" />
                            </div>
                        </div>                  
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="state">State :</label>
                                <div className="form-select">
                                <select name="state" id="state">
                                <option value="" >--</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="South Africa">South Africa</option>
                                </select>
                                
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="field">Field :</label>
                                <div className="form-select">
                                <select name="field" id="field">
                                <option value="" >--</option>
                                    <option value="Health">Health</option>
                                    <option value="Social Sciences">Social Sciences</option>
                                </select>
                                
                                </div>
                            </div>
                            </div>

                            <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="Password">Password :</label>
                                <input type="password" name="password" id="address" required="" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Repeat Passord :</label>
                                <input type="password" name="password_repeat" id="email" required="" />
                            </div>
                        </div>
                        <div class="form-submit">
                            <input type="submit" value="Reset All" class="submit" name="reset" id="reset" />
                            <input type="submit" value="Register" class="submit" name="submit" id="submit" />
                        </div>
                     </form>
                    </div>
                </div>            
            </fieldset>
            </form>
        </div>
    </div>
  )
}

export default Register;