import React from 'react';
import './Currentissue.css';
import TableContent from './TableContent';

function CurrentIssue() {
  return (
    <div className="current_issue">
        <div className="issue_left">
        
        <div className='articles'>
        <div className='latest_issue'>
            <div className='the_header'>
                <div className='cup_left'>
                    <span><i class="fa fa-trophy"></i></span>
                </div>
                <div className='title_right'>
                    <h2>Current Issue</h2>
                    {/*<h5>March 2024 <strong>|</strong> <span>Volume II, Issue 4</span></h5>*/}
                </div>
            </div>
            <div className='issue_content'>
                <div className='img-left'>
                    <img src='../../../images/editors.jpeg' alt='issue number'/>
                </div>
                <div className='content-right'>
                    <h4><a href='https://www.sportingpost.co.za/' target='_blank'>The Rise and Rise of Gambling in South Africa</a></h4>
                    <p><strong>-</strong>This article discusses the growth of the gambling industry in South Africa, highlighting key players and trends. Published in the Sporting Post. <span className='expand'>&#187;</span></p>
                    {/*
                    <p className='authors'>
                        <span>Dr. M S_Name, <strong><i class="fa-solid fa-building-columns"/> Institution  | </strong></span>
                        <span>Dr. M S_Name, <strong><i class="fa-solid fa-building-columns"/> Institution  | </strong></span>
                        <span>Dr. M S_Name, <strong><i class="fa-solid fa-building-columns"/> Institution  | </strong></span>
                        <span>Dr. M S_Name, <strong><i class="fa-solid fa-building-columns"/> Institution  | </strong></span>
                    </p>
                    */}
                </div>
            </div>
            </div>
            <div className='download_issue'>
                <div className='article_access'>
                    <span className='locked'> <a href='https://www.sportingpost.co.za/' target='_blank'>Open Access?</a> <i class='fa fa-lock-open'></i> | </span>
                    {/*<span> <a href='#'>Purchase</a> | </span>
                    <span><a href='#'> Citation</a>  | </span> */}
                    <span><a href='https://www.sportingpost.co.za/' target='_blank'>View Full Article</a></span>
                </div>
                <div className='downloads'>
                    <span className='cl'>Downloads:</span>
                    <span><a href='https://www.sportingpost.co.za/' target='_blank'><i class='fa fa-file-pdf'> </i> Pdf</a></span>
                    <span><a href='https://www.sportingpost.co.za/' target='_blank'><i class='fa fa-file-lines'> </i> ePub</a></span>
                </div>
            </div>
        </div>
        
        <div className='all-articles'>
            <div className='header'>
                <span><i class='fa fa-book'/></span>
                <h3>Journal Articles</h3>
            </div>
            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo1.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://mg.co.za/' target='_blank'>From Tradition to Technology: The Evolution of Sports Betting in South Africa</a></h4>
                        <div className='hr-display'>
                            <span>Published by <strong> Mail & Guardian</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>An exploration of how sports betting has evolved in South Africa, focusing on technological advancements and regulatory changes.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://mg.co.za/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        {/*<span><a href=''>Purchase</a> | </span>
                        <span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://mg.co.za/' target='_blank'>View Full Article</a></span>
                    </div>
                    {/*<div className='download'>
                        <span>Download:</span>
                        <span><a href='https://mg.co.za/' target='_blank'><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href='https://mg.co.za/' target='_blank'><i class='fa fa-file-lines'> </i> ePub</a></span>
                    </div>*/}         
                </div>
            </div>
            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo2.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://link.springer.com/' target='_blank'>Gambling in Sub-Saharan Africa: Traditional Forms and Emerging Technologies</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in Current Addiction Reports</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>A comprehensive overview of gambling practices across different regions in Africa, emphasizing traditional forms and new technologies.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://link.springer.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://link.springer.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo3.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://cceonlinenews.com/' target='_blank'>Exploring the Growth of Betting in South Africa Moving into 2024</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>by CCE Online News</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>This article analyzes the recent surge in betting activities in South Africa, driven by digital advancements and increasing mobile accessibility.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://cceonlinenews.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://cceonlinenews.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>
            
            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo4.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>Regulatory Challenges in Africa's Growing Gambling Sector</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in African Journal of Law and Criminology</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>A discussion on the challenges faced by regulators in managing the rapid expansion of the gambling sector in Africa.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/editors.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>Economic Impacts of Sports Betting in Nigeria</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in the Nigerian Journal of Economics</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>An examination of the economic effects of the sports betting industry in Nigeria, focusing on revenue generation and employment.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>
            
            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo2.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>Youth and Gambling: A Rising Concern in East Africa</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in the Journal of African Youth Studies</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>This study focuses on gambling patterns among young people in East Africa, highlighting the risks and prevalence of problem gambling.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo4.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>The Role of Mobile Apps in the Growth of Online Betting in Kenya</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in the Kenyan Journal of Communication and Technology</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>An article exploring how mobile betting apps have revolutionized the betting landscape in Kenya.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo1.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>Impact of COVID-19 on the African Gambling Industry</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in the African Economic Review</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>Analysis of how the pandemic affected gambling activities across Africa, with a focus on the shift to online platforms.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

            <div className="article_details">
                <div className='journal-middle'>
                    <div className="m-img">
                    <img src='../../../images/okeyo3.jpeg' alt='scaj journal image'/>
                    </div>
                    <div className="m-details">
                        <h4><a href='https://example.com/' target='_blank'>The Social and Cultural Implications of Gambling in Southern Africa</a></h4>
                        <div className='hr-display'>
                            <span>Published <strong>in the Southern African Journal of Cultural Studies</strong></span>
                            {/*<span><i class='fa fa-pen-nib'></i> Volume 2, Issue 4</span>*/}
                        </div>
                        <p><span>-</span>An exploration of the societal and cultural impact of gambling, particularly in South Africa and neighboring countries.<span className='expand'>&#187;</span></p>
                        {/*<p className='authors2'>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        <span>Dr. M S_Name, <i class="fa-solid fa-building-columns"/> Institution <strong> | </strong></span>
                        </p>*/}
                    </div>
                </div>
                <div className='journal-download'>
                    <div className='far-left'>
                        <span><a href='https://example.com/' target='_blank'>Open Access? </a><i class='fa fa-lock-open'></i> |  </span>
                        
                       {/*<span><a href=''>Citation</a> | </span>*/}
                        <span><a href='https://example.com/' target='_blank'>View Full Article</a></span>
                    </div>
                    <div className='download'>
                        {/*<span>Download:</span>
                        <span><a href=''><i class='fa fa-file-pdf'> </i> PDF</a></span>
                        <span><a href=''><i class='fa fa-file-lines'> </i> ePub</a></span>*/}
                    </div>            
                </div>
            </div>

        </div>
        <div className='pagination'>
                <span><a href=''>« Previous</a></span>
                <span><a href=''>Next »</a></span>
            </div>
        </div>
        <div className='issue_right'>            
            <div className='the_banner'>
                <div className="banner-image">
                    <img
                    src="https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80"
                    alt="scaj buy article"
                    />
                </div>
                <div className="banner-text">
                    <h4>Full Access Available to Subscribers Only</h4>
                    <a href=''>Subscribe Now <i class="fa fa-key"></i></a>
                </div>
            </div>
            <div className="table_of_content">
                <TableContent />
            </div>
        </div>
    </div>
  )
}

export default CurrentIssue;