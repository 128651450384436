export const FaqData = [
    {
        question: "What types of articles does SCAJ accept?",
        answer: "SCAJ accepts a range of article types, including original research articles, review articles, case studies, and theoretical papers. These submissions should focus on the economic, social, legal, and regulatory aspects of sports betting and casinos in Africa."
    },
    {
        question: "What is the review process for submitted manuscripts?",
        answer: "All submissions undergo a double-blind peer-review process. This means that the identities of both the authors and reviewers are kept confidential. Reviewers evaluate the manuscript's originality, methodological rigor, relevance, and contribution to the field. Authors may be required to make revisions based on the reviewers' feedback before final acceptance."
    },
    {
        question: "Are there any specific formatting requirements for manuscripts?",
        answer: "Yes, manuscripts should be prepared in English, using a standard font (e.g., Times New Roman, 12-point) and double-spaced. The manuscript should include a title page, abstract, keywords, main text organized into specific sections (Introduction, Literature Review, Methodology, Results, Discussion, Conclusion), and references. Authors must adhere to the APA (7th edition) citation style."
    },
    {
        question: "Does SCAJ offer open access options?",
        answer: "Yes, SCAJ offers open access publishing options. Authors can choose to make their articles freely accessible to the public, ensuring maximum visibility and impact. Information about the open access policy, including any associated fees, is available on the journal's website."
    },
    {
        question: "How often is the journal published?",
        answer: "SCAJ is published quarterly, with four issues released each year. The journal is available in an online format, and new issues are made available on the SCAJ website. This schedule ensures regular dissemination of high-quality research and timely discussions on current issues in sports betting and casino gaming in Africa."
    }    
]