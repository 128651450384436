import React from 'react';
import './Schedule.css';
import { NavLink } from 'react-router-dom';

function Schedule() {
  return (
   <div className="schedules">
        <div className="sch_header">
            <span>Publication Schedule</span>
            <p>Expected paper release <NavLink to='/details-of-publication-schedule'>View More Details</NavLink></p>
        </div> 
        <section className="today-box" id="today-box">
            <span className="breadcrumb">1ST ISSUE <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
            <h3 className="date-title">Submission deadline 15th March</h3>
            <div className="plus-icon">
            <i class="fa fa-hourglass" aria-hidden="true"></i>
            </div>
        </section>
        <section className='upcoming-events'>
            <div className="event">
            <i class="fa fa-hourglass flame" aria-hidden="true"></i>
                <h4 className="event__point">Submission deadline 20th June</h4>
                <span className="event__duration"></span>
                <p className="event__description">
                    2ND ISSUE
                </p>
            </div>
            
            <div className="event">
            <i class="fa fa-hourglass mild" aria-hidden="true"></i>
                <h4 className="event__point">Submission deadline 10th September</h4>
                <span className="event__duration"></span>
                <p className="event__description">
                    3RD ISSUE
                </p>
            </div>            
            <div className="event active">
            <i class="fa fa-hourglass live" aria-hidden="true"></i>
                <h4 className="event__point">Submission deadline 15th December</h4>
                <p className="event__description">4TH ISSUE</p>
            </div>
        </section>
   </div>
  )
}

export default Schedule;