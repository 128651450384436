import React from 'react';
import './Newsevents.css';
import {data} from './data/News';
import {events} from './data/News';

function NewsEvents() {
  return (
    <div className='news_events'>
        <div className='news_section'>
            <h1 className='news_title'>News</h1>
            {data.map ((item, index) => {
                return (
                    <div className='news_details'>
                        <div className='news_img_left'>
                            <img src={item.imgurl} alt='news item' />
                        </div>
                        <div className='descript'>
                            <h2><a href={item.storyurl}>{item.title}</a></h2>
                            <span>{item.industry} | <strong>{item.date}</strong></span>
                            <p>{item.brief}</p>
                            <div className='full_story'>
                                <span><a href={item.storyurl} target='_blank'>Full story &raquo;</a></span>
                            </div>
                        </div>
                    </div> 
                )
            })}
            <div className='pagination'>
                <span><a href=''>« Previous</a></span>
                <span><a href=''>Next »</a></span>
            </div>                
        </div>
        <div className='page-right'>
        <div className="calendar_events">
            <h1 className='cal_header'>Events Calendar </h1>
            {events.map ((item, index) => {
                return (
                    <div className='event_details'>
                        <img src={item.imgs} alt=''/>
                        <h2><a href={item.url} target='_blank'>{item.title}</a></h2>
                        <span><strong>{item.workshop}</strong> | <span className='lt'>{item.date}</span></span>
                        <p>{item.details}</p>
                        <span className='full_article'><a href={item.url} target='_blank'>Full article &raquo;</a></span>
                    </div>
                )
            })}
            <div className='pagination'>
                <span><a href=''>« Previous</a></span>
                <span><a href=''>Next »</a></span>
            </div>
        </div>
        <div className="announcements">
            <div className='the_details'>
                <div className='anounce_header'>
                    <h2>Announcements</h2>
                </div>
                <div className='items_anounced'>
                    <div className='awards'>
                        <h4>Call for Abstract</h4>
                    <ol className="ol-awards months" >
                        <li>Research topic of paper</li>
                        <li>Research topic of paper</li>                   
                    </ol>
                    
                    </div>
                    <div className="grants">
                    <h4>Call for Articles</h4>
                    <div class="list-grants">
                        <ol>
                            <li><a href="#">Research topic </a>
                            </li>
                        </ol>
                    </div>
                    </div>
                    <div className='clr'></div>
                    <div className="opportunities">
                        <h4>Other Opportunities</h4>
                        <div class="list-opport">
                        <ul>
                            <li><i className='fa fa-chevron-right'/><a href=''>Register for Conferences</a></li>
                            <li><i className='fa fa-chevron-right'/><a href=''>Research Grants upcoming</a></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>          
        </div>
    </div>
  )
}

export default NewsEvents;