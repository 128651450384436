import React from 'react';
import './Sidebar.css';

function Sidebar() {
  return (
    <div className='sidebar-content'>
        <div className='first-part'>
        <div className='action-items'>
            <span className='pricing'>
                <span>
                    $69 <small>/ a</small>
                </span>
            </span>
                <h2 className="title">Our Pricing</h2>
                    <p className='info'>Explore specific research areas</p>
                <ul className='features'>
                    <li>
                        <span className='icons'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                            </svg>
                        </span>
                        <span>Peer review per article/manuscript- <strong>$69</strong> </span>                
                    </li>
                    <li>
                        <span className='icons'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                            </svg>
                        </span>
                        <span>Journal publication open access to readers - <strong>$149</strong> </span>                
                    </li>
                    <li>
                        <span className='icons'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                            </svg>
                        </span>
                        <span>Journal publication (subscription based for readers) - <strong>$1000</strong> </span>                                    
                    </li>
                </ul>
                <div className='action'>
                    <p>Search Now!</p>
                    <svg class="arrows">
                    <path class="a1" d="M0 0 L30 32 L60 0"></path>
                    <path class="a2" d="M0 20 L30 52 L60 20"></path>
                    <path class="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </div> 
                <div className='search_item'>
                    <form action='' className='form_search'>
                        <input type='text' placeholder='Title' className='form_input'/>
                        <input type='text' placeholder='Author' className='form_input'/>
                        <input type='text' placeholder='Issue No.' className='form_input'/>
                        <button className='form_submit'>Search</button>
                    </form>
                </div>                 
        </div>
    {/*
    <div className='resources_section'>
        <h3>Resources</h3>
        <ul>
            <li><a href=''>Sports Betting</a></li>
            <li><a href=''>Industry Report</a></li>
            <li><a href=''>Data</a></li>
            <li><a href=''>Academic Databases</a></li>
            <li><a href=''>Regulatory Policies</a></li>
            <li><a href=''>Key Terms</a></li>
        </ul>
    </div>
    */}
    </div>
    {/*
    <div className='second-part'>           
    <div className='archives'>
        <h3>Archives</h3>            
        <form action='#'>
            <select name='articles'>
                <option>All</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
                <option>2017</option>
            </select>
        </form>
    </div>
    
    <div className='featured_list'>
        <h2>Featured</h2>
        <div className='lastest'>
            <h4>Latest Published Articles</h4>
            <ul>
                <li><i class='fa fa-chevron-right'/><a href=''>Latest article title</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>Latest article title</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>Latest article title</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>Latest article title</a></li>
            </ul>
        </div>
        <div className='lastest'>
            <h4>Upcoming Events</h4>
            <ul>
                <li><i class='fa fa-chevron-right'/><a href=''>Upcoming event title</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>Upcoming event title</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>Upcoming event title</a></li>
            </ul>
        </div>
        <div className='lastest'>
            <h4>Key Research Findings</h4>
            <ul>
                <li><i class='fa fa-chevron-right'/><a href=''>The research findings brief</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>The research findings brief</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>The research findings brief</a></li>
                <li><i class='fa fa-chevron-right'/><a href=''>The research findings brief</a></li>
            </ul>
        </div>
    </div>
    </div>
    */}  
    </div>
  )
}

export default Sidebar;