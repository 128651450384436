export const Articles = [
    {
        title: 'Gambling in Sub-Saharan Africa: Traditional Forms and Emerging Technologies',
        publication_year: '2024',
        publication_month: '08',
        category: 'Current Addiction Reports',
        issue: '',
        pdf: 'https://link.springer.com/',
        epub: 'https://link.springer.com/'
    },
    {
        title: 'The Rise and Rise of Gambling in South Africa',
        publication_year: '2024',
        publication_month: '01',
        category: 'Sporting Post',
        issue: '',
        pdf: 'https://link.springer.com/',
        epub: 'https://link.springer.com/'
    },
    {
        title: 'From Tradition to Technology: The Evolution of Sports Betting in South Africa',
        publication_year: '2024',
        publication_month: '05',
        category: 'Mail & Guardian',
        issue: '',
        pdf: 'https://mg.co.za/',
        epub: 'https://mg.co.za/'
    },
    {
        title: 'Exploring the Growth of Betting in South Africa Moving into 2024',
        publication_year: '2024',
        publication_month: '08',
        category: 'CCE Online News',
        issue: '',
        pdf: 'https://cceonlinenews.com/',
        epub: 'https://cceonlinenews.com/'
    },
    {
        title: 'Regulatory Challenges in Africa\'s Growing Gambling Sector',
        publication_year: '2024',
        publication_month: '07',
        category: 'African Journal of Law and Criminology',
        issue: '',
        pdf: 'https://example.com/',
        epub: 'https://example.com/'
    },
]