import React from 'react';
import './Terms.css'

function Terms() {
  return (
    <div className='terms_conditions'>
      <h1>Terms & Conditions for the Sports Casino Africa Journal (SCAJ)</h1>
      <div className='terms'>
        <h3>1. Introduction</h3>
        <p>Welcome to the Sports Casino Africa Journal (SCAJ), an online publication dedicated to advancing the understanding of the economic, social, legal, and regulatory aspects of sports betting and casinos in Africa. By accessing or submitting content to the SCAJ, you agree to adhere to the following Terms & Conditions. These terms govern your use of the journal's website, services, and content, as well as your rights and obligations as an author, reviewer, or user.</p>
        <h3>2. Definitions</h3>
        <ul>
          <li><strong>SCAJ</strong> refers to the Sports Casino Africa Journal and all associated services.</li>
          <li><strong>Author</strong> refers to any individual or group submitting a manuscript for consideration to SCAJ.</li>
          <li><strong>Reviewer </strong>refers to any individual invited to review a manuscript submitted to SCAJ.</li>
          <li><strong>User </strong>refers to anyone accessing the SCAJ website or content.</li>
          <li><strong>Manuscript </strong>refers to any document submitted for consideration to SCAJ.</li>
        </ul>
        <h3>3. Submission of Manuscripts</h3>
        <h4>3.1. Originality and Plagiarism</h4>
        <p>Authors must ensure that their work is original and not plagiarized. Manuscripts submitted to SCAJ should not have been published previously or be under consideration for publication elsewhere. Plagiarism, including self-plagiarism, is strictly prohibited. Authors are responsible for ensuring that their work does not infringe on the copyright of others.</p>
        <h4>3.2. Ethical Standards</h4>
        <p>All research involving human subjects must adhere to ethical standards, including obtaining necessary approvals from relevant ethics committees and obtaining informed consent from participants. Authors must disclose any potential conflicts of interest.</p>
        <h4>3.3. Submission Process</h4>
        <p>Manuscripts must be submitted through the SCAJ online submission portal. The submission must include all required components, including the manuscript, abstract, keywords, references, tables, figures, and any supplementary material. The corresponding author must confirm that all co-authors have agreed to the submission and the terms of publication.</p>
        <h3>4. Review Process</h3>
        <h4>4.1. Peer Review</h4>
        <p>All submitted manuscripts undergo a double-blind peer-review process. Reviewers evaluate the manuscript's originality, methodology, relevance, and contribution to the field. The identities of both authors and reviewers are kept confidential to ensure impartiality.</p>
        <h4>4.2. Reviewer Responsibilities</h4>
        <p>Reviewers are expected to provide objective, constructive feedback and to maintain the confidentiality of the manuscript. They must disclose any potential conflicts of interest that could bias their review.</p>
        <h4>4.3. Editorial Decision</h4>
        <p>The editorial team makes the final decision on the acceptance, revision, or rejection of a manuscript based on the reviewers' feedback. Decisions are communicated to the corresponding author along with reviewer comments. Authors may be required to revise their manuscripts and resubmit them within a specified timeframe.</p>
        <h3>5. Publication Ethics</h3>
        <h4>5.1. Authorship and Acknowledgments</h4>
        <p>All individuals who have made a significant contribution to the research should be listed as authors. The corresponding author must ensure that all co-authors have reviewed and approved the final version of the manuscript. Proper acknowledgment should be given to individuals or organizations that contributed to the research but do not meet the criteria for authorship.</p>
        <h4>5.2. Data Availability</h4>
        <p>Authors must be willing to provide data and materials related to their research for verification and replication purposes, subject to ethical and legal constraints. Data availability statements should be included in the manuscript.</p>
        <h4>5.3. Corrections and Retractions</h4>
        <p>If errors are discovered in published works, authors must promptly notify the editorial team. Corrections, retractions, or expressions of concern will be issued as necessary to maintain the integrity of the academic record.</p>
        <h3>6. Intellectual Property and Licensing</h3>
        <h4>6.1. Copyright</h4>
        <p>Authors retain the copyright of their published articles but grant SCAJ the exclusive license to publish the work. SCAJ may use the published content in any media or format, subject to the terms of the open access license.</p>
        <h4>6.2. Open Access and Licensing</h4>
        <p>SCAJ offers open access publishing options under Creative Commons licenses. Authors can choose to make their work freely available to the public, subject to specific licensing terms (e.g., CC BY, CC BY-NC). These licenses allow others to share, use, and build upon the work, provided proper attribution is given.</p>
        <h4>6.3. Third-Party Content</h4>
        <p>Authors are responsible for obtaining permission to use any third-party content (e.g., images, tables) included in their manuscripts. Proper attribution and acknowledgments must be provided for all third-party content.</p>
        <h3>7. User Conduct and Use of Content</h3>
        <h4>7.1. Access and Use</h4>
        <p>Users may access SCAJ content for personal, non-commercial use. Any other use, including reproduction, distribution, or modification of content, requires permission from the journal. Users must not engage in any activity that disrupts the operation of the journal's website or services.</p>
        <h4>7.2. User Contributions</h4>
        <p>Users may submit comments or feedback on published articles. Such contributions must be respectful, relevant, and free of offensive or defamatory content. SCAJ reserves the right to remove inappropriate comments and block users who violate these terms.</p>
        <h3>8. Disclaimer and Limitation of Liability</h3>
        <p>SCAJ does not guarantee the accuracy, completeness, or reliability of the content published in the journal. The views expressed in the articles are those of the authors and do not necessarily reflect the views of SCAJ. The journal is not liable for any damages arising from the use of its content.</p>
        <h3>9. Privacy and Data Protection</h3>
        <p>SCAJ is committed to protecting the privacy of authors, reviewers, and users. Personal information collected during the submission and review process is used solely for editorial and publishing purposes and is not shared with third parties without consent. The journal's privacy policy outlines the specific details of data collection and usage.</p>
        <h3>10. Amendments to Terms & Conditions</h3>
        <p>SCAJ reserves the right to amend these Terms & Conditions at any time. Changes will be communicated to authors, reviewers, and users through the journal's website. Continued use of the journal's services constitutes acceptance of the revised terms.</p>
        <h3>11. Governing Law and Dispute Resolution</h3>
        <p>These Terms & Conditions are governed by the laws of the jurisdiction in which SCAJ operates. Any disputes arising from the use of the journal's services will be resolved through mediation or arbitration, as per the journal's dispute resolution policy.</p>
        <h3>12. Contact Information</h3>
        <p>For any questions or concerns regarding these Terms & Conditions, please contact the editorial office at <span>scaj478@gmail.com</span></p>
        <p>By using the Sports Casino Africa Journal, submitting manuscripts, or engaging with the content, you agree to comply with these Terms & Conditions. Thank you for your cooperation and contribution to the journal.</p>
      </div>
    </div>
  )
}

export default Terms;